<template>
  <div class="page-container">
    <Filter :params="params" @search="searchData" add @add="addNew"></Filter>
    <Table :tableData="tableDataList" :total="total" @update="updatePage">
      <el-table-column prop="date" label="发布日期" width="120px" />
      <el-table-column prop="productName" label="产品类型" width="150px" />
      <el-table-column prop="version" label="版本号" width="180px" />
      <el-table-column prop="platformName" label="平台" width="120px" />
      <el-table-column prop="packageName" label="安装包" width="250px" >
        <template #default="{row}">
          <a :href="row.url" download="software">{{row.packageName}}</a>
        </template>
      </el-table-column>
      <el-table-column prop="remark" label="版本更新内容" />
      <el-table-column label="操作" fixed="right" width="80px">
        <template #default="{row}">
          <el-button type="danger" plain size="mini" @click="deleteDetail(row)">删除</el-button>
        </template>
      </el-table-column>
    </Table>
    <el-dialog title="新增软件" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <el-form ref="detailformRef" class="dialog-form" :model="detailform" :rules="detailformRules" label-width="120px">
        <el-form-item label="发布日期" prop="date">
          <el-date-picker
            v-model="detailform.date"
            type="date"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            placeholder="发布日期"
            clearable
            :disabled-date="disabledDate"
          />
        </el-form-item>
        <el-form-item label="version" prop="version">
          <el-input v-model="detailform.version" placeholder="version" clearable />
        </el-form-item>
        <el-form-item label="产品类型" prop="productName">
          <el-select v-model="detailform.productName" placeholder="产品类型" clearable @change="onSelectProduct">
            <el-option
              v-for="item in productList"
              :key="item.name"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="平台" prop="platformName">
          <el-select v-model="detailform.platformName" :disabled="detailform.productName == 'OTA'" placeholder="平台" clearable>
            <template v-for="item in platformList" :key="item.name">
              <el-option
                v-show="!(item.name == 'CAB 32&64' && detailform.productName != 'OTA')"
                :label="item.name"
                :value="item.name"
              />
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="安装包" prop="filePath">
          <!-- <el-input v-model="detailform.multipartFile" placeholder="安装包" />
          <el-button @click="importFile" type="primary" size="mini" plain>导入Excel</el-button> -->
          <input ref="inputFile" type="file" @change="uploadFile">
          <el-progress style="max-width: 500px;" :percentage="wsProgress"></el-progress>
        </el-form-item>
        <el-form-item label="版本更新内容" prop="remark">
          <el-input
            type="textarea"
            :autosize="{ minRows: 4}"
            placeholder="版本更新内容"
            v-model="detailform.remark"
            clearable
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button size="small" type="primary" @click="editSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { request, GET } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, toRefs } from "@vue/reactivity";
import { ElMessage, ElMessageBox } from "element-plus";
import Table from "@/components/table/index";
import Filter from "@/components/table/filter";
import { wsDomain } from '@/common/config';
import { ossUpload } from '@/utils/oss'

export default {
  components: {
    Table,
    Filter,
  },
  mixins: [common],
  setup() {
    const state = reactive({
      query: {},
      page: 1,
      size: 10,
      total: 0,
      dialogFormVisible: false,
      tableDataList: [],
      detailformRef: null,
      detailform: {
        date: '',
        version: '',
        productName: '',
        platformName: '',
        filePath: '',
        fileName: '',
        fileSize: '',
        remark: ''
      },
      params: [{
        key: "version",
        value: "",
        label: "版本号",
      }],
      productList: [],
      platformList: [],
      wsProgress: 0,
      inputFile: null
    })
    const detailformRules = reactive({
      date: {
        required: true,
        message: '请选择发布日期',
        trigger: 'change',
      },
      version: {
        required: true,
        message: '请输入版本号',
        trigger: 'blur',
      },
      productName: {
        required: true,
        message: '请选择产品类型',
        trigger: 'change'
      },
      platformName: {
        required: true,
        message: '请选择平台',
        trigger: 'change',
      },
      filePath: {
        required: true,
        message: '请上传安装包',
        trigger: 'change',
      },
      remark: {
        required: true,
        message: '请输入版本更新内容',
        trigger: 'blur',
      }
    })
    var wsp = null
    const getData = async () => {
      console.log("query", state.query);
      try {
        const res = await request("getPackageList", {
          ...state.query,
          pageNum: state.page,
          pageSize: state.size,
        });
        state.tableDataList.length = 0
        state.tableDataList = res.records
        state.total = res.total;
      } catch (error) {
        console.error(error);
      }
    };
    const getDataMap = () => {
      try {
        GET('getVersionProductList')
        .then(res => {
          state.productList = res || []
        })
        GET('getVersionPlatformList')
        .then(res => {
          state.platformList = res || []
        })
      } catch (error) {
        console.error(error)
      }
    }
    const searchData = async (data) => {
      state.query = data;
      getData();
    };
    const updatePage = (page, size) => {
      state.page = page || 1;
      state.size = size || 10;
      getData();
    };
    const addNew = () => {
      for (let key of Object.keys(state.detailform)) {
        state.detailform[key] = "";
      }
      state.dialogFormVisible = true;
      console.log(state.detailform);
    }
    const editDetail = async (row) => {
      try {
        for (let key of Object.keys(state.detailform)) {
          if (row[key]) {
            state.detailform[key] = row[key];
          } else {
            state.detailform[key] = "";
          }
        }
        state.detailform.id = row.id
        state.dialogFormVisible = true
        console.log("editDetail", row);
      } catch (error) {
        console.error(error);
      }
    };
    const deleteDetail = async (row) => {
      try {
        await ElMessageBox.confirm(
          '确定删除？',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning',
          }
        )
        await request("deleteVersionPackage", {
          id: row.id
        })
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "删除成功!"
        })
      } catch (error) {
        console.error(error)
      }
    } 
    const editSubmit = async () => {
      console.log('onSubmit', state.detailform)
      try {
        // const formData = new FormData();
        // Object.keys(state.detailform).forEach((key) => {
        //   formData.append(key, state.detailform[key])
        // })
        state.detailformRef && await state.detailformRef.validate()
        await request("addVersionPackage", state.detailform)
        state.wsProgress=0
        state.inputFile.value = ''
        state.dialogFormVisible = false
        getData()
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
      } catch (error) {
        console.error(error)
      }
    }
    const uploadFile = async (e) => {
      try {
        const file = e.target.files[0]
        if (!file) return
        const fileUrl = await ossUpload(file.name, file, {
          path: '/ota/software',
          timestamp: false,
          progress: (p) => {
            state.wsProgress = Math.round(p*100)
          }
        })
        console.log('uploadFile', fileUrl)
        state.detailform.filePath = fileUrl || ''
        state.detailform.fileName = file.name || ''
        state.detailform.fileSize = file.size || ''
      } catch (error) {
        state.inputFile.value = ''
        console.error(error)
      }
    }
    const webProgress = () => {
      const url = wsDomain[process.env.NODE_ENV] + '/admin/uploadProgress'
      wsp = new WebSocket(url);
      wsp.onopen = function(evt) { 
        console.log("Connection open ...", evt); 
        // ws.send("Hello WebSockets!");
      };

      wsp.onmessage = function(evt) {
        state.wsProgress = Number(evt.data || 0)
        console.log( "Received Message: " + evt.data);
        // wsp.close();
      };

      wsp.onclose = function(evt) {
        console.log("Connection closed.", evt);
      }; 
    }
    const disabledDate = (time) => {
      return time.getTime() > Date.now()
    }
    const onSelectProduct = (value) => {
      if (value == 'OTA') {
        state.detailform.platformName = 'CAB 32&64'
      } else {
        state.detailform.platformName = ''
      }
    }
    getData();
    getDataMap();
    return {
      ...toRefs(state),
      detailformRules,
      getData,
      searchData,
      updatePage,
      addNew,
      editDetail,
      deleteDetail,
      editSubmit,
      uploadFile,
      webProgress,
      disabledDate,
      onSelectProduct
    };
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
</style>