<template>
  <div class="page-container">
    <Table ref="tableRef" :params="params" :request="searchData" tooltip-effect="light">
      <template #search>
        <el-form-item>
          <el-button type="primary" @click="addNew">新建升级任务</el-button>
        </el-form-item>
      </template>
      <template #toolBar>
        <el-input-number
          v-model="duration"
          controls-position="right"
          :disabled="realTime"
          :min="1"
          size="small"
          style="margin-right: 10px"
        />
        <el-switch v-model="realTime" active-text="实时更新" @change="onRealTimeChange" />
      </template>
      <el-table-column type="index" label="序号" width="60px" />
      <el-table-column prop="robotSn" label="机器人编码" />
      <el-table-column prop="cabinetSn" label="控制器编码" />
      <el-table-column prop="productType" label="产品类型">
        <template #default="{row}">
          {{ productTypeMap[row.productType] }}
        </template>
      </el-table-column>
      <el-table-column prop="lastVersion" label="当前版本(平台)" />
      <el-table-column prop="targetVersion" label="目标版本(平台)" />
      <el-table-column prop="status" label="升级状态">
        <template #default="{row}">
          {{ otaUpgradeMap[row.status] }}
        </template>
      </el-table-column>
      <el-table-column prop="errorMessage" label="失败原因">
        <template #default="{row}">
          <el-popover
            placement="top-start"
            :width="320"
            trigger="hover"
          >
            <template #default>
              {{row.errorMessage}}
            </template>
            <template #reference>
              <span class="multi-line-text">{{row.errorMessage}}</span>
            </template>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="升级操作时间" width="150" />
    </Table>
    <el-dialog title="新建升级任务" top="5vh" v-model="dialogFormVisible" destroy-on-close :modal="true" :append-to-body="true">
      <div class="section-title">选择机器人</div>
      <div style="margin-bottom: 10px">
        <el-space>
          机器人编号：
          <el-input v-model="robotId" placeholder="请输入机器人编号" clearable @keyup.enter="getUpgradeInfo" />
          <el-button size="small" type="primary" @click="getUpgradeInfo">查询</el-button>
        </el-space>
      </div>
      <template v-if="upgradeData.robotSN">
        <el-descriptions direction="vertical" :column="3" border>
          <el-descriptions-item label="机器人编码">{{ upgradeData.robotSN }}</el-descriptions-item>
          <el-descriptions-item label="控制柜编码">{{ upgradeData.cabinetSN }}</el-descriptions-item>
          <el-descriptions-item label="机器人状态">{{ robotStatusMap[upgradeData.status] }}</el-descriptions-item>
        </el-descriptions>
        <div class="section-title">任务配置</div>
        <el-table class="dialog-table" :data="upgradeData.productList" border stripe>
          <el-table-column type="index" label="序号" width="60px" />
          <el-table-column prop="productName" label="产品类型" ></el-table-column>
          <el-table-column prop="version" label="当前版本/平台"></el-table-column>
          <el-table-column prop="upgrade" label="是否升级">
            <template #default="{ row }">
              <el-radio :label="row.productId" v-model="productId">{{ '' }}</el-radio>
            </template>
          </el-table-column>
          <el-table-column prop="targetData" label="目标版本/平台">
            <template #default="{ row }">
              <el-select v-if="productId == row.productId" v-model="targetData" value-key="version">
                <el-option
                  v-for="item in row.productVersionList"
                  :key="item.version"
                  :label="`${item.version}`"
                  :value="item"
                />
              </el-select>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <template #footer>
        <el-button :disabled="!upgradeData.robotSN" size="small" type="primary" @click="formSubmit">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { GET } from "@/utils/request";
import { common } from "@/common/mixin";
import { reactive, ref, toRefs } from "vue";
// import { watch } from 'vue'
import { ElMessage } from "element-plus";
import Table from "@/components/elp/table";
import { otaUpgradeList, otaUpgradeMap, robotStatusMap, arrayToMap } from "@/common/constant"

import { reqUpgradeList, reqUpgradeVersion, reqUpgradeSend } from "@/api/upgrade"

export default {
  components: {
    Table,
  },
  mixins: [common],
  setup() {
    const productTypeList = ref([])
    const productTypeMap = ref({})
    const state = reactive({
      realTime: false, // 是否实时更新
      timer: null, // 定时器
      duration: 10, // 时长
      tableRef: null,
      dialogFormVisible: false,
      detailformRef: null,
      detailform: {
        robotSn: '',
        cabinetSn: '',
        productId: '',
        productName: '',
      },
      robotId: '',
      productId: '',
      targetData: '',
      upgradeData: {},
      params: [
        {
          key: "robotSn",
          value: "",
          label: "机器人编号",
        },
        {
          key: "cabinetSn",
          value: "",
          label: "控制柜编号",
        },
        {
          type: "Select",
          key: "productType",
          value: "",
          label: "产品类型",
          options: {
            data: productTypeList,
            label: "name",
            value: "id"
          }
        },
        {
          type: "Select",
          key: "upgradeStatus",
          value: "",
          label: "升级状态",
          options: {
            data: otaUpgradeList,
            label: "label",
            value: "value"
          }
        },
        {
          type: "Date",
          key: "otaUpgradeTimeRange",
          value: [],
          label: "操作日期",
          options: {
            "type": "datetimerange",
            "format": "YYYY-MM-DD HH:mm:ss",
            "value-format": "YYYY-MM-DD HH:mm:ss",
            "range-separator": "至",
            "start-placeholder": "开始日期",
            "end-placeholder": "结束日期",
            "default-time": [new Date(2000, 1, 1, 0, 0, 0), new Date(2000, 1, 1, 23, 59, 59)]
          }
        },
      ],
    })
    const detailformRules = reactive({
      title: {
        required: true,
        message: '请输入帖子标题',
        trigger: 'blur',
      },
      content: {
        required: true,
        message: '请输入帖子内容',
        trigger: 'blur',
      }
    })
    // watch(() => state.query,
    //   (cur, pre) => {
    //     console.log("新值:", cur, "老值:", pre);
    //     if (state.query.tag) {
    //       state.query[state.query.tag] = true
    //     }
    //   },
    //   { immediate: true, deep: true }
    // )
    const searchData = async (params) => {
      return reqUpgradeList(params)
    }
    const getProductTypeList = async () => {
      try {
        const res = await GET('getVersionProductList')
        productTypeList.value = res || []
        productTypeMap.value = arrayToMap(productTypeList.value, 'id', 'name')
      } catch (error) {
        console.error(error)
      }
    }
    const onRealTimeChange = (val) => {
      if (val && state.duration) {
        state.timer = setInterval(() => {
          state.tableRef?.update()
        }, state.duration * 1000)
      } else {
        state.timer && clearInterval(state.timer)
      }
    }
    const addNew = () => {
      state.dialogFormVisible = true
      state.robotId = ''
      state.productId = ''
      state.targetData = ''
      state.upgradeData = {}
    }
    const getUpgradeInfo = async () => {
      try {
        const res = await reqUpgradeVersion({
          id: state.robotId
        })
        state.upgradeData = res || {}
      } catch (error) {
        console.error(error)
      }
    }
    const formSubmit = async () => {
      try {
        if (!state.productId) {
          ElMessage({
            showClose: true,
            type: "warning",
            message: "请选择产品类型"
          })
          return
        }
        else if (!state.targetData?.platform) {
          ElMessage({
            showClose: true,
            type: "warning",
            message: "请选择目标版本/平台"
          })
          return
        }
        const product = state.upgradeData.productList.find(item => item.productId == state.productId)
        await reqUpgradeSend({
          robotSn: state.upgradeData.robotSN,
          cabinetSn: state.upgradeData.cabinetSN,
          productId: state.productId,
          productName: product.productName,
          currentVersion: product.version,
          downloadUrl: state.targetData.url,
          signature: state.targetData.signature,
          platform: state.targetData.platform,
          targetVersion: state.targetData.version,
        })
        ElMessage({
          showClose: true,
          type: "success",
          message: "提交成功!"
        })
        state.dialogFormVisible = false
        state.tableRef?.update()
      } catch (error) {
        console.error(error)
      }
    }
    getProductTypeList()
    return {
      productTypeList,
      productTypeMap,
      otaUpgradeMap,
      robotStatusMap,
      ...toRefs(state),
      detailformRules,
      searchData,
      onRealTimeChange,
      addNew,
      getUpgradeInfo,
      formSubmit,
    }
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  height: 100%;
  padding: 50px 10px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.list-item {
  margin-bottom: 0;
}
.box-card {
  margin-bottom: 10px;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.multi-line-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.section-title {
  font-size: 16px;
  font-weight: bold;
  margin: 10px 0;
}
</style>
